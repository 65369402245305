
.logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 50px 0;
  margin-top: 100px;
}


.logo-object {}

.logo-text {
  font-size: 32px;
  margin: 10px 0;
}

.logo-link {
  color: inherit;
  text-decoration: none;
}

.logo-img {
  width: 100%;
}